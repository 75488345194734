import * as React from "react"
import { graphql } from "gatsby"
import PdfLink from '../components/PdfLink/PdfLink'
import Timestamps from '../regulatory-documents-timestamps.json'

import Layout from "../components/layout";

const Regulatory = ({data}) => {
  const pdfs = data.allFile.edges

  return (
    <Layout>
      <h1 className="mb-8">Regulatory Announcements</h1>
      {pdfs
        .sort((a,b) => Date.parse(String(Timestamps[a.node.name]).replace(/(nd|st|rd|th)/g, '')) < Date.parse(String(Timestamps[b.node.name]).replace(/(nd|st|rd|th)/g, ''))  ? 1 : -1)
        .map(pdf => {
          return (
            <div className="flex justify-between py-2" key={pdf.node.id}>
                <PdfLink name={String(pdf.node.name).replace(/\s\d+\b/g,'')} publicURL={pdf.node.publicURL} /> 
                <span>{Timestamps[pdf.node.name]}</span>
            </div>
          )
      })}
      <hr className="mt-2"/>
    </Layout>
  )
}

export default Regulatory

export const pageQuery = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "regulatory-data" } }) {
        edges {
          node {
            extension
            name
            id
            publicURL
            relativePath
            modifiedTime
            birthTime(formatString: "Do MMMM YYYY")
          }
        }
      }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "do MMMM, YYYY")
          title
          description
        }
      }
    }
  }
`