import * as React from "react"

class PdfLink extends React.Component {
    
    render() {

        return (
            <div><a 
                href={this.props.publicURL}
                target="_blank" 
                rel="noreferrer">
                    {this.props.name}
            </a> (downloadable pdf)</div>
          )
    }
}

export default PdfLink